import styled, { css } from 'styled-components'

export const ReorderPanelStyled = styled('div')(
  ({ theme }) => css`
    .reorder-item {
      border: 1px solid ${theme.colors.grey600};

      &:nth-child(even) {
        background-color: ${theme.colors.grey300};
      }

      .reorder-item-main {
        padding: ${theme.spacing(1, 0, 0, 0.5)};
        display: flex;
      }
    }
  `
)

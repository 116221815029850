import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { hasValue, isEmpty } from '@digital-magic/ts-common-utils'
import { routes } from '@constants/routes'
import { formatDateShort } from '@utils/date-utils'
import { RequestErrorHandler } from '@api/types'
import { LayoutTypeId, LayoutTypeListItem } from '@api/endpoints/buildings/layoutTypes'
import { useDeleteLayoutType, useGetLayoutTypes } from '@api/endpoints/buildings/layoutTypes/api'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useSnackbar } from 'notistack'
import { Delete } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Button, RouterButton } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { Table, TableColumn } from '@controls/Table'
import { ReorderPanel } from '@pages/Admin/LayoutTypes/LayoutTypeForm/LayoutTypeOptions/ReorderPanel'
import { LayoutTypesStyled } from './LayoutTypes.styles'

export const LayoutTypes: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const snackbar = useSnackbar()
  const [reorderMode, setReorderMode] = React.useState(false)

  const onDeleteLayoutTypeError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'ConstraintViolationError') {
      snackbar.enqueueSnackbar(t('pages.admin.layout_type.errors.delete_constraint'))
    } else {
      defaultErrorHandler(e)
    }
  }

  const deleteLayoutType = useDeleteLayoutType({ onError: onDeleteLayoutTypeError })
  const getLayoutTypes = useGetLayoutTypes({ onError: defaultErrorHandler })

  const onRowClick = (row: LayoutTypeListItem): void => {
    navigate(generatePath(routes.Admin.LayoutTypesEdit, { layoutTypeId: row.id }))
  }

  const onDeleteLayoutType =
    (id: LayoutTypeId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteLayoutType.mutate(id)
      }
    }

  // TODO: Add translations!
  const tableColumns: ReadonlyArray<TableColumn<LayoutTypeListItem>> = React.useMemo(
    () => [
      {
        key: 'name',
        title: t('pages.admin.layout_types.fields.name'),
        sortable: true
      },
      {
        key: 'buildingType',
        title: t('pages.admin.layout_types.fields.building_type'),
        sortable: true,
        render: (row) => t(`enums.building_type.${row.buildingType.code}`)
      },
      {
        key: 'floorsCount',
        title: t('pages.admin.layout_types.fields.floors'),
        sortable: true
      },
      {
        key: 'apartmentsCount',
        title: t('pages.admin.layout_types.fields.apartments'),
        sortable: true
      },
      {
        key: 'createdAt',
        title: t('pages.admin.layout_types.fields.created'),
        sortable: true,
        render: (row) => formatDateShort(row.createdAt)
      },
      {
        key: null,
        title: null,
        render: (row) => (
          <ButtonWithConfirmation
            color="error"
            size="small"
            onConfirmResult={onDeleteLayoutType(row.id)}
            confirmTitle={t('global.consent.delete.title')}
            confirmMessage={t('global.consent.delete.message')}
          >
            <Delete />
          </ButtonWithConfirmation>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  )

  return (
    <LayoutTypesStyled>
      <h1>{t('pages.admin.layout_types.title')}</h1>
      {hasValue(getLayoutTypes.data) && reorderMode ? (
        <ReorderPanel items={getLayoutTypes.data} onCompleted={() => setReorderMode(false)} />
      ) : (
        <>
          <Table
            columns={tableColumns}
            data={getLayoutTypes.data ?? []}
            onRowClick={onRowClick}
            initialSortConfig={{ column: 'name', direction: 'ASC' }}
          />
          <Box display="flex" columnGap={2} mt={2}>
            <RouterButton to={routes.Admin.LayoutTypesNew}>{t('global.buttons.add')}</RouterButton>
            <Button onClick={() => getLayoutTypes.refetch()}>{t('global.buttons.refresh')}</Button>
            <Button
              disabled={isEmpty(getLayoutTypes.data) || getLayoutTypes.data.length === 0}
              onClick={() => setReorderMode(true)}
            >
              {t('global.buttons.reorder')}
            </Button>
          </Box>
        </>
      )}
    </LayoutTypesStyled>
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { hasValue, reorderArray } from '@digital-magic/ts-common-utils'
import { LayoutTypeList, useUpdateLayoutTypesOrder } from '@api/endpoints/buildings/layoutTypes'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import classNames from 'classnames'
import { DragIndicator } from '@mui/icons-material'
import { Box } from '@mui/material'
import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd'
import { Button } from '@controls/Button'
import { ReorderPanelStyled } from '@pages/Admin/LayoutTypes/LayoutTypeForm/LayoutTypeOptions/ReorderPanel.styles'

type Props = {
  items: LayoutTypeList
  onCompleted: () => void
}

export const ReorderPanel: React.FC<Props> = ({ items, onCompleted }) => {
  const { t } = useTranslation()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const [orderedItems, setOrderedItems] = React.useState<LayoutTypeList>([])

  const updateLayoutTypesOrder = useUpdateLayoutTypesOrder({ onError: defaultErrorHandler })

  React.useEffect(() => {
    setOrderedItems(items.concat().sort((a, b) => a.orderNumber - b.orderNumber))
  }, [items])

  const onDragEnd = (result: DropResult): void => {
    if (
      hasValue(result.destination) &&
      hasValue(result.source.index) &&
      result.destination.index !== result.source.index
    ) {
      const reordered = reorderArray(orderedItems, result.source.index, result.destination.index).map((v, i) => ({
        ...v,
        orderNumber: i
      }))
      setOrderedItems(reordered)
      updateLayoutTypesOrder.mutate({
        items: reordered.map((v) => ({
          item: v.id,
          index: v.orderNumber
        }))
      })
    }
  }

  return (
    <ReorderPanelStyled>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-items-list">
          {(provided) => (
            <Box
              display="flex"
              flexDirection="column"
              rowGap={0.5}
              width="100%"
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {orderedItems.map((o, i) => (
                <Draggable key={o.id} draggableId={o.id} index={i}>
                  {(provided, snapshot) => (
                    <div
                      className={classNames({ 'reorder-item': true, dragging: snapshot.isDragging })}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="reorder-item-main">
                        <div {...provided.dragHandleProps}>
                          <DragIndicator />
                        </div>
                        <div>{o.name}</div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Box display="flex" columnGap={2} mt={2}>
        <Button onClick={onCompleted}>{t('global.buttons.back')}</Button>
      </Box>
    </ReorderPanelStyled>
  )
}
